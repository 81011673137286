import React from "react";
import "./App.css";
import bigDogImage from "./images/BigDogThingsWebsite_500w.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Arecah.</h1>
      </header>
      <a
        href="http://www.bigdogthings.com.au"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={bigDogImage} alt="Big Dog Things" />
      </a>
    </div>
  );
}

export default App;
